import axios from 'axios';
import CryptoJS from 'crypto-js';

const SECRET_KEY = '67mfXOVbcGm37ut8';

// Encryption function with IV
const encryptData = (data) => {
  const iv = CryptoJS.lib.WordArray.random(16);
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  return {
    iv: iv.toString(CryptoJS.enc.Base64),
    payload: encryptedData,
  };
};

// Decryption function
const decryptData = (responseData) => {
  try {
    const iv = CryptoJS.enc.Base64.parse(responseData.iv);
    const encryptedPayload = responseData.payload;
    const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, key, { iv });
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

    if (!decryptedText) return null;
    return JSON.parse(JSON.parse(decryptedText));
  } catch (error) {
    console.error('Decryption failed:', error);
    return null;
  }
};

const token = window.localStorage.getItem('auth');
const instance = axios.create({
  baseURL: 'https://backenddev.medicappae.com/api',
});

// https://backend.medicappae.com/api

// https://backenddev.medicappae.com/api

// http://localhost:3005/api

// Add token to headers if available
if (token) {
  instance.defaults.headers.common['authorization'] = `Bearer ${token}`;
}

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
instance.defaults.headers.common['timezone'] = timezone;
instance.defaults.headers.post['Content-Type'] = 'application/json';

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const isFileIncluded =
      config.data instanceof FormData ||
      Object.values(config.data || {}).some(
        (value) => value instanceof File || value instanceof Blob
      );

    if (isFileIncluded) {
      console.warn('File detected. Skipping encryption for this request.');
      return config; // Allow file requests to proceed without encryption
    }

    if (config.data) {
      const { iv, payload } = encryptData(config.data);
      config.data = { iv, payload }; // Include IV with encrypted payload
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    if (response && response.data && response.data.iv && response.data.payload) {
      response.data = decryptData(response.data); // Decrypt the response payload using IV
    }
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      window.location.reload(true);
    }
    return Promise.reject(error);
  }
);

export default instance;
